<template>
  <div class="businessDomainList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button text="新增业务域" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
       <v-input label="业务域名称" v-model="searchParams.businessRegionName"></v-input>
        <v-select2 label="授权对象" v-model="searchParams.regionId" v-bind="regionIdParams"></v-select2>
        <v-datepicker label="创建时间段" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-input label="创建人" v-model="searchParams.inuser"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, regionIdURL } from './api'

export default {
  name: 'BusinessDomainList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      maxDate: new Date(),
      regionIdParams: {
        searchUrl: regionIdURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        },
        response: {
          text: 'regionName'
        }
      },
      searchParams: {
        businessRegionName: '',
        regionId: '',
        startTime: '',
        endTime: '',
        inuser: ''
      },
      headers: [
        {
          prop: 'businessRegionName',
          label: '业务域名称'
        },
        {
          prop: 'regionName',
          label: '授权对象'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'inuser',
          label: '创建人'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'businessDomainForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'businessDomainForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
